import React, { useState } from 'react';
import { X, CreditCard, Bitcoin, Wallet, ArrowRight, DollarSign } from 'lucide-react';
import { useWallet } from '@/hooks/useWallet';
import { StripePaymentForm } from '../StripePaymentForm';
import { paymentService } from '@/services/api/payments';
import { supportedChains } from '@/config/web3';
import toast from 'react-hot-toast';

interface Property {
  id: string;
  title: string;
  minInvestment: number;
  targetAmount: number;
  raisedAmount: number;
  maxInvestment: number;
}

interface InvestmentModalProps {
  isOpen: boolean;
  onClose: () => void;
  property: Property;
  onInvest: (amount: number, method: 'stripe' | 'crypto') => void;
}

export function InvestmentModal({ isOpen, onClose, property, onInvest }: InvestmentModalProps) {
  const [amount, setAmount] = useState<number>(property?.minInvestment || 0);
  const [paymentMethod, setPaymentMethod] = useState<'stripe' | 'crypto'>('stripe');
  const [showPaymentForm, setShowPaymentForm] = useState(false);
  const [isRecurring, setIsRecurring] = useState(true); // Set to true by default
  const [selectedChain, setSelectedChain] = useState(supportedChains.ethereum.id);
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState('');
  const { isConnected, connect, isLoading: isWalletLoading, chain, switchNetwork } = useWallet();
  const [paymentIntent, setPaymentIntent] = useState<{ clientSecret: string; id: string } | null>(null);

  if (!isOpen || !property) return null;

  const remainingAmount = property.targetAmount - property.raisedAmount;

  const validateAmount = (value: number) => {
    if (value < property.minInvestment) {
      setError(`Minimum investment amount is $${property.minInvestment.toLocaleString()}`);
      return false;
    }
    
    if (value > property.maxInvestment) {
      setError(`Maximum investment amount is $${property.maxInvestment.toLocaleString()}`);
      return false;
    }

    if (value > remainingAmount) {
      setError(`Maximum available investment amount is $${remainingAmount.toLocaleString()}`);
      return false;
    }

    setError('');
    return true;
  };

  const handlePaymentSuccess = async () => {
    try {
      onInvest(amount, 'stripe');
      onClose();
      toast.success('Investment successful!');
    } catch (error) {
      console.error('Payment success handler error:', error);
      toast.error('Failed to complete investment');
    }
  };

  const handleInvest = async () => {
    if (!validateAmount(amount)) {
      return;
    } 

    try {
      if (paymentMethod === 'stripe') {
        // Add detailed logging
        const paymentParams = {
          amount,
          propertyId: property.id,
          method: 'stripe' as const,
          isRecurring
        };
        
        console.log('Creating payment intent with params:', paymentParams);
        
        const response = await paymentService.createPaymentIntent(paymentParams);

        console.log('Payment intent API response:', response);

        if (!response.success || !response.data) {
          throw new Error(response.message || 'Failed to create payment intent');
        }

        setPaymentIntent(response.data);
        setShowPaymentForm(true);
        return;
      }

      if (paymentMethod === 'crypto') {
        if (!isConnected) {
          await connect();
          return;
        }

        if (chain?.id !== selectedChain && switchNetwork) {
          await switchNetwork(selectedChain);
          return;
        }

        setIsProcessing(true);
        const response = await paymentService.processCryptoPayment(
          amount,
          selectedChain,
          property.id
        );

        if (!response.success) {
          throw new Error('Failed to process payment');
        }

        onInvest(amount, 'crypto');
        onClose();
        toast.success('Investment successful!');
      }
    } catch (error) {
      console.error('Investment error:', {
        error,
        type: error instanceof Error ? error.constructor.name : typeof error,
        message: error instanceof Error ? error.message : String(error)
      });

      if (error instanceof Error) {
        if (error.message.includes('rejected')) {
          toast.error('Transaction was rejected');
        } else {
          toast.error('Payment failed. Please try again.');
        }
      } else {
        toast.error('An unexpected error occurred');
      }
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <div className="absolute inset-0 bg-black/50 backdrop-blur-sm" onClick={onClose}></div>
      <div className="relative bg-white rounded-2xl shadow-xl max-w-md w-full mx-4 animate-fade-in">
        <div className="p-6">
          <div className="flex justify-between items-center mb-6">
            <h3 className="text-xl font-semibold">Invest in {property.title}</h3>
            <button onClick={onClose} className="text-gray-400 hover:text-gray-600">
              <X className="w-5 h-5" />
            </button>
          </div>

          {!showPaymentForm ? (
            <div className="space-y-6">
              {paymentMethod === 'stripe' && (
                <div>
                  <label className="flex items-center gap-2 mb-4">
                    <input
                      type="checkbox"
                      checked={isRecurring}
                      onChange={(e) => setIsRecurring(e.target.checked)}
                      className="rounded text-primary-600 focus:ring-primary-500"
                    />
                    <span className="text-sm text-gray-700">Set up recurring monthly investment</span>
                  </label>
                </div>
              )}

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Investment Amount
                </label>
                <div className="relative">
                  <DollarSign className="absolute left-3 top-3 w-5 h-5 text-gray-400" />
                  <input
                    type="number"
                    min={property.minInvestment}
                    max={Math.min(property.maxInvestment, remainingAmount)}
                    step={100}
                    value={amount}
                    onChange={(e) => setAmount(Number(e.target.value))}
                    className="w-full pl-10 pr-4 py-2 rounded-lg border border-gray-200 focus:ring-2 focus:ring-primary-500 focus:border-primary-500"
                  />
                </div>
                {error && (
                  <p className="mt-1 text-sm text-red-600">{error}</p>
                )}
                <p className="mt-1 text-sm text-gray-500">
                  Min: ${property.minInvestment.toLocaleString()} | 
                  Max: ${Math.min(property.maxInvestment, remainingAmount).toLocaleString()}
                </p>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Payment Method
                </label>
                <div className="grid grid-cols-2 gap-4">
                  <button
                    type="button"
                    onClick={() => setPaymentMethod('stripe')}
                    className={`flex items-center justify-center gap-2 p-4 rounded-lg border ${
                      paymentMethod === 'stripe'
                        ? 'border-primary-500 bg-primary-50 text-primary-700'
                        : 'border-gray-200 hover:border-primary-500'
                    }`}
                  >
                    <CreditCard className="w-5 h-5" />
                    <span>Card</span>
                  </button>
                  <button
                    type="button"
                    onClick={() => setPaymentMethod('crypto')}
                    className={`flex items-center justify-center gap-2 p-4 rounded-lg border ${
                      paymentMethod === 'crypto'
                        ? 'border-primary-500 bg-primary-50 text-primary-700'
                        : 'border-gray-200 hover:border-primary-500'
                    }`}
                  >
                    <Bitcoin className="w-5 h-5" />
                    <span>Crypto</span>
                  </button>
                </div>
              </div>

              {paymentMethod === 'crypto' && (
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Select Network
                  </label>
                  <select
                    value={selectedChain}
                    onChange={(e) => setSelectedChain(Number(e.target.value))}
                    className="w-full px-4 py-2 rounded-lg border border-gray-200 focus:ring-2 focus:ring-primary-500 focus:border-primary-500"
                  >
                    {Object.values(supportedChains).map((chain) => (
                      <option key={chain.id} value={chain.id}>
                        {chain.name}
                      </option>
                    ))}
                  </select>
                </div>
              )}

              <button
                onClick={handleInvest}
                disabled={isWalletLoading || isProcessing}
                className="w-full bg-primary-600 text-white py-3 rounded-lg font-medium hover:bg-primary-700 transition-colors disabled:opacity-50 disabled:cursor-not-allowed flex items-center justify-center gap-2"
              >
                {isWalletLoading ? (
                  <>
                    <div className="w-5 h-5 border-2 border-white border-t-transparent rounded-full animate-spin" />
                    Connecting Wallet...
                  </>
                ) : isProcessing ? (
                  <>
                    <div className="w-5 h-5 border-2 border-white border-t-transparent rounded-full animate-spin" />
                    Processing Payment...
                  </>
                ) : (
                  <>
                    {paymentMethod === 'crypto' && !isConnected ? (
                      <>
                        <Wallet className="w-5 h-5" />
                        Connect Wallet
                      </>
                    ) : (
                      <>
                        Continue
                        <ArrowRight className="w-5 h-5" />
                      </>
                    )}
                  </>
                )}
              </button>
            </div>
          ) : (
            paymentIntent && (
              <StripePaymentForm
                amount={amount}
                onSuccess={handlePaymentSuccess}
                onCancel={() => setShowPaymentForm(false)}
                clientSecret={paymentIntent.clientSecret}
                isRecurring={isRecurring}
              />
            )
          )}
        </div>
      </div>
    </div>
  );
}