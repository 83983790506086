import api from '../axios';
import type { ApiResponse } from '@/types/response';
import type { Investment } from '@/types/investment';
import type { PaymentIntent, RecurringPaymentDetails } from '@/types/payment';
import type { CreatePaymentIntentParams, PaymentConfirmation } from './index';

export const stripeService = {
  async createPaymentIntent(params: CreatePaymentIntentParams): Promise<ApiResponse<PaymentIntent>> {
    try {
      console.log('Creating payment intent with params:', {
        ...params,
        propertyId: params.propertyId,
        isRecurring: params.isRecurring,
        setupFutureUsage: params.isRecurring ? 'off_session' : undefined
      });
      
      // Use different endpoint for vouchers
      const endpoint = params.propertyId === 'voucher' 
        ? '/payments/voucher/create-intent'
        : '/payments/create-intent';
      
      const { data } = await api.post<ApiResponse<PaymentIntent>>(endpoint, {
        ...params,
        setupFutureUsage: params.isRecurring ? 'off_session' : undefined
      });

      console.log('Payment intent API response:', {
        success: data.success,
        message: data.message,
        data: data.data,
        subscription: data.data?.subscriptionId,
        error: data.error
      });

      if (!data.success) {
        throw new Error(data.message || 'Failed to create payment intent');
      }

      return data;
    } catch (error) {
      console.error('Create payment intent error:', {
        error,
        type: error instanceof Error ? error.constructor.name : typeof error,
        message: error instanceof Error ? error.message : String(error),
        response: error instanceof Error && 'response' in error ? (error as any).response?.data : undefined,
        isRecurring: params.isRecurring
      });
      throw error;
    }
  },

  async confirmPayment(paymentIntentId: string, isRecurring?: boolean): Promise<ApiResponse<PaymentConfirmation>> {
    try {
      console.log('Confirming payment intent:', {
        paymentIntentId,
        isRecurring
      });
      
      const { data } = await api.post<ApiResponse<PaymentConfirmation>>('/payments/confirm', {
        paymentIntentId,
        isRecurring
      });

      console.log('Payment confirmation API response:', {
        success: data.success,
        message: data.message,
        investment: data.data?.investment,
        subscription: data.data?.subscriptionId,
        error: data.error
      });

      if (!data.success || !data.data?.investment) {
        throw new Error(data.message || 'Payment confirmation failed');
      }

      return data;
    } catch (error: any) {
      console.error('Confirm payment error:', {
        error,
        type: error instanceof Error ? error.constructor.name : typeof error,
        message: error instanceof Error ? error.message : String(error),
        response: error.response?.data,
        isRecurring
      });
      throw new Error(error.response?.data?.message || 'Failed to confirm payment');
    }
  },

  async getSubscriptionDetails(subscriptionId: string): Promise<ApiResponse<RecurringPaymentDetails>> {
    try {
      console.log('Getting subscription details:', subscriptionId);
      const { data } = await api.get<ApiResponse<RecurringPaymentDetails>>(
        `/payments/recurring/${subscriptionId}`
      );

      console.log('Subscription details response:', {
        success: data.success,
        data: data.data,
        error: data.error
      });

      if (!data.success || !data.data) {
        throw new Error(data.message || 'Failed to get subscription details');
      }

      return data;
    } catch (error) {
      console.error('Get subscription details error:', {
        error,
        type: error instanceof Error ? error.constructor.name : typeof error,
        message: error instanceof Error ? error.message : String(error),
        subscriptionId
      });
      throw error;
    }
  },

  async updateSubscriptionAmount(
    subscriptionId: string,
    newAmount: number
  ): Promise<ApiResponse<RecurringPaymentDetails>> {
    try {
      console.log('Updating subscription amount:', {
        subscriptionId,
        newAmount
      });

      const { data } = await api.put<ApiResponse<RecurringPaymentDetails>>(
        `/payments/recurring/${subscriptionId}/amount`,
        { amount: newAmount }
      );

      console.log('Update subscription amount response:', {
        success: data.success,
        data: data.data,
        error: data.error
      });

      return data;
    } catch (error) {
      console.error('Update subscription amount error:', error);
      throw error;
    }
  },

  async pauseSubscription(subscriptionId: string): Promise<ApiResponse<void>> {
    try {
      console.log('Pausing subscription:', subscriptionId);
      const { data } = await api.post<ApiResponse<void>>(
        `/payments/recurring/${subscriptionId}/pause`
      );

      console.log('Pause subscription response:', {
        success: data.success,
        message: data.message,
        error: data.error
      });

      return data;
    } catch (error) {
      console.error('Pause subscription error:', error);
      throw error;
    }
  },

  async resumeSubscription(subscriptionId: string): Promise<ApiResponse<void>> {
    try {
      console.log('Resuming subscription:', subscriptionId);
      const { data } = await api.post<ApiResponse<void>>(
        `/payments/recurring/${subscriptionId}/resume`
      );

      console.log('Resume subscription response:', {
        success: data.success,
        message: data.message,
        error: data.error
      });

      return data;
    } catch (error) {
      console.error('Resume subscription error:', error);
      throw error;
    }
  },

  async cancelSubscription(subscriptionId: string): Promise<ApiResponse<void>> {
    try {
      console.log('Cancelling subscription:', subscriptionId);
      const { data } = await api.post<ApiResponse<void>>(
        `/payments/recurring/${subscriptionId}/cancel`
      );

      console.log('Cancel subscription response:', {
        success: data.success,
        message: data.message,
        error: data.error
      });

      return data;
    } catch (error) {
      console.error('Cancel subscription error:', error);
      throw error;
    }
  }
};